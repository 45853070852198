import React from 'react';

import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-scroll';

import NavBar from 'aulatime/src/components/NavBar';

import Logo from '../img/logo_nav.png';

const NavBarMarketing = () => (
  <NavBar
    logo={<img src={Logo} alt="Aula Spanish Time" className="img-fluid" />}
    nav={(menuClick, offset) => (
      <Nav navbar>
        <NavItem>
          <a href="/" className="nav-link">Home</a>
        </NavItem>
        <NavItem>
          <a href="/special-classes-online" className="nav-link flashing">Special - Classes Online</a>
        </NavItem>
        <NavItem className="tier-1">
          <a href="/refer-a-friend" className="nav-link">Refer A Friend</a>
        </NavItem>
        <NavItem>
          <Link href="#" className="nav-link" activeclassname="active" to="contacto" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
            Contact Us
          </Link>
        </NavItem>
      </Nav>
    )}
  />
);

export default NavBarMarketing;
